import React from 'react'
import './header.scss'

export default function Header() {
    return (
        <div>
            <div className="module_header">
                <div className="overlay_absolute"></div>
            </div>
        </div>
    )
}